import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Decentralized crowdsourced moderation",
  "route": "/decentralized-crowdsourced-moderation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Decentralized crowdsourced moderation`}</h1>
    <p>{`It would be prohibitively difficult to censor or moderate donations on Smartlike network while staying decentralized, anonymous and free. We rather opted for an unmoderated public ledger on the lower level and apply decentralized crowdsourced moderation on the application level where filters for search and feeds can be controlled by users.`}</p>
    <p>{`There may be multiple views of the donations ledger. We implement the following two lines of defense against unwanted content on smartlike.org:`}</p>
    <p><strong parentName="p">{`User blacklist`}</strong></p>
    <p>{`Unwanted authors or whole websites can be muted. Content is privately filtered locally, muted authors are hidden. (It's possible to have individual editorial policies: one subscribes to an online newspaper, for instance, and mutes unwanted journalists.)`}</p>
    <p><strong parentName="p">{`Decentralized crowdsourced moderation`}</strong></p>
    <p>{`While centralized moderation is expensive and controversial, a major challenge for a crowdsourced solution in a system where users are anonymous and cannot be banned is to prevent manipulation and vandalism. Smartlike implements a decentralized crowdsourced moderation:`}</p>
    <p>{`Users help categorize content and report inappropriate cases.
Moderators cluster report data to enforce the rules of their target audiences, filter out abuse and vandalism producing multiple clusters since there's no single truth - what is a joke to one, might be a religious offense to another.
Users explicitly authorize moderators and set content filtering options. All filters are optional, it is the user who decides on whether or how to filter the Internet.
Moderators are financially incentivized: users pay a tiny fee of 0.01 USD when they report inappropriate content, the fees are collected and paid out to moderators the users are subscribed to when the content category is arbitrated.`}</p>
    <p>{`Internet content moderation has grown to a multi-billion dollar business. Smartlike removes intermediaries and directly connects users to competing moderators so that they could deliver the best value for very little money. Until more alternative moderators join the platform, Smartlike will provide a placeholder moderation service with a basic anti-vandalism `}<a parentName="p" {...{
        "href": "/docs/moderation-policy"
      }}>{`policy`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      